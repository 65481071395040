<template>
  <!--top-->
  <div  id="top"  :class="{ top: true, totop: isTop }">
    <a href="/"><img src="@/assets/images/logo1.png" class="ph_logo fl_le" /></a>
    <div class="wrap clearfloat">
      <!--      <div class="logo"><a href="/"><img src="images/logo.png"/></a></div>-->
      <div class="nav">
        <ul class="clearfloat">
          <li>
            <h2 class="clogo"><a href="/"><img src="@/assets/images/logo1.png"/></a><span class="navBtn"></span></h2>

          </li>
          <li>
            <h2><a href="/about">公司概况</a><span class="navBtn"></span></h2>
            <div class="navDown">
              <a href="/about#about2">公司简介</a>
<!--              <a href="/about#about3">领导致辞</a>-->
              <!--            <a href="/about#about4">公司领导</a>-->
<!--              <a href="/about#about5">业务构架</a>-->
              <a href="/about#about7">发展战略</a>
              <a href="/about#about9">企业荣誉</a>
              <a href="/about#about6">发展历程</a>
            </div>
          </li>
          <li>
            <h2><a href="/ppcp">品牌产品</a><span class="navBtn"></span></h2>
            <div class="navDown">
              <a href="/ppcp#firstPage">液态奶</a>
              <a href="/ppcp#secondPage">奶粉</a>
              <a href="/ppcp#3rdPage">创意乳品</a>
              <a href="/cpsy">产品追溯</a>
              <a href="/gxbsy">工信部追溯</a>
            </div>
          </li>
          <li>
            <h2><a href="/chanye">产业布局</a><span class="navBtn"></span></h2>
            <div class="navDown">
              <a href="/chanye#cy1">农业种植</a>
              <a href="/chanye#cy2">饲料加工</a>
              <a href="/chanye#cy3">奶牛饲养</a>
              <a href="/chanye#cy4">产品研发</a>
              <a href="/chanye#cy5">乳品加工</a>
              <a href="/chanye#cy6">质量管控</a>
              <a href="/chanye#cy7">冷链运输</a>
              <a href="/chanye#cy8">智慧营销</a>
            </div>
          </li>
          <li>
            <h2><a href="/news">新闻中心</a><span class="navBtn"></span></h2>
            <div class="navDown">
              <a href="/news#xw1">公司新闻</a>
              <a href="/news#xw2">媒体报道</a>
            </div>
          </li>

          <li>
            <h2><a href="/qywh">企业文化</a><span class="navBtn"></span></h2>
<!--            <div class="navDown">-->
<!--              <a href="/news#xw1">公司新闻</a>-->
<!--            </div>-->
          </li>
          <li>
            <h2><a href="/dj">党建工作</a><span class="navBtn"></span></h2>
            <div class="navDown">
              <a href="/dj#dj1">党建工作</a>
<!--              <a href="/qywh">企业文化</a>-->
              <a href="/dj#dj3">社会责任</a>
            </div>
          </li>

          <li>
            <h2><a href="/gonggao">通知公告</a><span class="navBtn"></span></h2>
            <div class="navDown">
              <a href="/gonggao#gonggao2">招采信息</a>
              <a href="/gonggao#gonggao1">信息公开</a>
            </div>
          </li>

          <li>
            <h2><a href="/zhaopin">人才招聘</a><span class="navBtn"></span></h2>
            <div class="navDown">
              <a href="/zhaopin#rc1">社会招聘</a>
              <a href="/zhaopin#rc2">校园招聘</a>
<!--              <a href="/zhaopin#rc3">实习生招聘</a>-->
<!--              <a href="/about">关于我们</a>-->
            </div>
          </li>

        </ul>

        <div class="topRight clearfloat">
          <!--        <div class="topLang">-->
          <!--          <span class="topLangBtn"></span>-->
          <!--          <a class="topLangEn" href="">English</a>-->
          <!--          <a class="topLangCh" href="">China</a>-->
          <!--        </div>-->
          <div class="topSearch">
            <span class="topSearchBtn"></span>
            <div class="topSearchWrap clearfloat">
              <input type="text" id="searchinput" name="searchinput" v-model="searchinput" placeholder="搜索一下"/>
              <button @click="searchpost()"></button>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="muen">
      <span class="muenX"></span>
      <span class="muenY"></span>
      <span class="muenZ"></span>
    </div>
  </div>
  <!--top-->
</template>

<script>
export default {
  data() {
    return {
      searchinput: "",
      searchdata: "",
      isTop: false,
    }
  },
  mounted() {
    $(".nav ul li").hover(function () {
      $(this).find(".navDown").stop().slideToggle();

    })
    $(".nav ul li .navBtn").click(function () {
      //$(this).parents("h2").toggleClass("on").siblings(".navDown").slideToggle();
      $(this).parents("li").siblings("li").find("h2").removeClass("on").siblings(".navDown").slideUp();
    })
    $(".topSearchBtn").click(function () {
      $(".topSearchWrap").fadeIn();
    })
    let i = 0;
    $(".muen").click(function (event) {
      $(this).toggleClass("on");
      if (i == 0) {
        $("#top .nav").stop().animate({"left": '0'});
        i = 1;
      } else if (i == 1) {
        $("#top .nav").stop().animate({"left": '-100%'});
        i = 0;
      }
      event.stopPropagation();
    })

    window.addEventListener("scroll", this.scrollToTop, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop, true);
  },
  methods: {
    scrollToTop() {
      let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
      //为了计算距离顶部的高度，当高度大于50显示回顶部图标，小于50则隐藏
      if (scrollTop > 60) {
        this.isTop = true;
      } else {
        this.isTop = false;
      }
    },
    searchpost() {
      debugger;
      this.$router.push({
        path: '/searchlist?title='+this.searchinput,
        query: {
          title: this.searchinput
        }
      })
    }
    //searchpost
  }
}
</script>
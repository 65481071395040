<template>
  <div class="menu_ico">
    <top_menu></top_menu>
  </div>
  <div id="home" class="fullpage">


    <div class="section ho_pag1 active">
      <div class="container-fluid"  v-for="(item, index) in testdata5">
<!--      <img src="@/assets/images/2.png" class="ho_jt"/>-->
        <img :src="item.source" alt="辉山产品baner"/>
<!--      <img src="@/assets/images/banner_icon.png" class="banner_font" style="left:-150%;width: 600px"/>-->
      <div id="carousel-example-generic" class="carousel slide" data-ride="carousel" data-interval="3000">
        <!-- Indicators -->
        <!-- <div class="carousel-inner" role="listbox">
          <div class="item active"><img src="images/banner.png" /></div>
          <div class="item"><img src="images/banner.png" /></div>
          <div class="item"><img src="images/banner.png" /></div>
        </div> -->
        <!-- Controls -->
      </div>
      </div>

    </div>
    <!-- ho_pag1 -->
    <div class="section ho_pag2">
<!--       <img src="@/assets/images/2.png" class="ho_jt" />-->
      <div class="container-fluid">
      <div class="swiper hsSwiper1">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in testdata2">
            <div class="intro">
              <img :src="item.source" alt="辉山产品"/>
            </div>
          </div>
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-pagination"></div>
      </div>
      </div>
<!--      <img src="@/assets/images/niunai_img.png" class="niunai_img"/>-->

    </div>
    <!-- ho_pag2 -->
    <div class="section ho_pag3 bg">
<!--      <img src="@/assets/images/2.png" class="ho_jt"/>-->
<!--      <div  class="list container animate" rel="0,50%,50%,50%">-->
<!--        <div class="ho_img" v-for="(item,index) in testdata" :key="index">-->
<!--          <router-link :to="{path:'/content',query:{id:item.id}}">-->
<!--            <span>{{ (item.title || '') }}&lt;!&ndash; <img src="@/assets/images/5.png" /> &ndash;&gt;</span>-->
<!--            <img :src="item.images" class="pic"/>-->
<!--          </router-link>-->
<!--        </div>-->
<!--      </div>-->
      <div class="container-fluid">
      <div class="swiper hsSwiper2">
        <div class="swiper-wrapper">

          <div class="swiper-slide" v-for="(item, index) in testdata3" :key="index">
            <div class="intro">
              <img :src="item.images" :alt="item.title">
              <div class="text">
                <div class="text1">{{(item.title||'')}}</div>
                <div class="text2">{{(item.description||'')}}</div>
                <div class="text3">{{(item.keywords||'')}}</div>
                <p  class="text4" v-html="item.remark"></p>
              </div>
            </div>
          </div>


        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-pagination"></div>
      </div>
      </div>
    </div>

    <!-- ho_pag3 -->
    <div class="section ho_pag4">
      <div class="container">
        <div class="marketing-wrap">
          <div class="row">
            <div class="col-lg-6">
              <!-- Corpix Marketing Content Start -->
              <div class="marketing-content swiper hsSwiper4">
                <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item, index) in testdata4" :key="index">
                  <div class="section-title">
                    <img :src="item.images" :alt="item.title">
                    <h2 class="title"><a :href="'/content?id='+item.id">{{(item.title||'')}}</a></h2>
                  </div>
<!--                  <h2 class="tkl"></h2>-->
<!--                  <p class="ppt">{{(item.description||'')}} </p>-->
                </div>
                </div>
              </div>
              <!-- Corpix Marketing Content End -->
            </div>
            <div class="col-lg-6">
              <div class="marketing-item-wrap">
                <!-- Marketing Item Start -->
                <div class="marketing-item">

                  <div class="marketing-text">
                    <dl>
                      <dt><img href="#" src="@/assets/images/t1.jpg" class="tit"/></dt>
                      <dd v-for="(item, index) in testdata" :key="index">
                        <span>{{(item.publishTime||'').slice(0,10).replace(/-/g,'/')}}</span>&nbsp;&nbsp;&nbsp;<a :href="'/content?id='+item.id">{{(item.title||'')}}</a>
                      </dd>
                    </dl>                  </div>
                </div>
                <!-- Marketing Item End -->
                <!-- Marketing Item Start -->
                <div class="marketing-item marketing-item-2">

                  <div class="marketing-text">
                            <dl>
                              <dt><img href="#" src="@/assets/images/t2.jpg" class="tit"/></dt>
                              <dd v-for="(item, index) in testdata1" :key="index">
                                <span>{{(item.publishTime||'').slice(0,10).replace(/-/g,'/')}}</span>&nbsp;&nbsp;&nbsp;<a :href="'/content?id='+item.id">{{(item.title||'')}}</a>
                              </dd>
                            </dl>
                  </div>
                </div>
                <!-- Marketing Item End -->
              </div>
            </div>
          </div>
        </div>
      </div>
<!--      <img src="@/assets/images/2.png" class="ho_jt"/>-->
<!--      <div class="font_lay" >-->
<!--        <a href="/about" class="ad"><img src="@/assets/images/icon.png"/></a>-->
<!--        <a href="/dj" class="ad"><img src="@/assets/images/icon1.png"/></a>-->
<!--        <img href="#" src="@/assets/images/icon5.png" class="tit"/>-->
<!--        <a href="/gonggao" class="ad"><img src="@/assets/images/icon2.png"/></a>-->
<!--        <a href="/zhaopin" class="ad"><img src="@/assets/images/icon4.png"/></a>-->
<!--        <dl>-->
<!--          <dt>媒体报道</dt>-->
<!--          <dd v-for="(item, index) in testdata1" :key="index">-->
<!--            <a :href="'/content?id='+item.id" :alt="(item.publishTime||'').slice(0,10)">{{(item.title||'')}}</a>-->
<!--          </dd>-->
<!--        </dl>-->
<!--      </div>-->

    </div>
    <!-- ho_pag4 -->
<!--    <div class="section ho_pag5">-->
<!--      <div class="footer_lay" >-->
<!--        <dl>-->
<!--          <dt>联系我们</dt>-->
<!--          <dd>-->
<!--            <p>-->
<!--              <strong>全国免费服务热线：</strong>-->
<!--              400-660-1951<br/>-->
<!--              400-606-1951（奶粉）-->
<!--            </p>-->
<!--            <p>-->
<!--              <strong>公司地址：</strong>-->
<!--              辽宁省沈阳市皇姑区黄河南大街111号甲-->
<!--            </p>-->
<!--          </dd>-->
<!--        </dl>-->
<!--        &lt;!&ndash; footer_lay &ndash;&gt;-->
<!--        <ul class="ewm_lay">-->
<!--          <li><img src="@/assets/images/icon11.png" class="pic"/><img src="@/assets/images/dy.png" class="ewm"/></li>-->
<!--          <li><img src="@/assets/images/icon12.png" class="pic"/><img src="@/assets/images/wb.jpg" class="ewm"/></li>-->
<!--          <li><img src="@/assets/images/icon13.png" class="pic"/><img src="@/assets/images/wx.png" class="ewm"/></li>-->
<!--        </ul>-->
<!--      </div>-->
<!--      <div class="">-->
<!--        <a href="/about" style="color:#FFF;text-decoration: none;">关于辉山  |</a>-->
<!--        <a target="_blank"  style="color:#FFF;text-decoration: none;" href="http://beian.miit.gov.cn/">辽ICP备14009966号-5</a>-->
<!--      </div>-->
<!--    </div>-->

    <fo_oter></fo_oter>
  </div>
</template>
<style>
.swiper-button-prev:after, .swiper-button-next:after{
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}
/*为了去除中心化效果*/
.swiper-slide-next {
  transform: none;
}
.swiper-slide-prev {
  transform: none;
}
.container-fluid {
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
}
</style>
<script>
import top_menu from '../components/menu.vue';
import fo_oter from '../components/footer.vue'

import "@/assets/css/style.css";
// import "@/assets/css/jquery.fullPage.css";
import "@/assets/bootstrap/bootstrap.min.css";
// import "@/assets/js/jquery-1.9.1.min.js";
// import "@/assets/js/jquery-ui-1.10.3.min.js";
// import "@/assets/js/jquery.fullPage.min.js";
// import "@/assets/bootstrap/bootstrap.min.js";
// import "@/assets/js/slider.js";

import $ from "jquery/dist/jquery.min.js";
window.$ = $;
import 'jquery-ui-dist/jquery-ui.min.js'
import 'jquery-ui-dist/jquery-ui.min.css'

import fullpage from "fullpage.js/dist/fullpage.js";
import "fullpage.js/dist/fullpage.css";

//vue.use(VueFullPage);
import {GETHOMEDATA1, GETHOMEDATA2, GETHOMEDATA3, GETHOMEDATA4} from '../apis/home.js'
import axios from 'axios'
// import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js';
// Import Swiper styles
// import "swiper/swiper.min.css";
// import "swiper/modules/navigation/navigation.min.css" ;
// import "swiper/modules/pagination/pagination.min.css" ;
// import "swiper/modules/scrollbar/scrollbar.min.css";
// import Swiper core and required modules
// import SwiperCore, { Pagination, Navigation, Virtual } from 'swiper';
// install Swiper modules
// SwiperCore.use([Pagination, Navigation, Virtual]);

import Swiper from 'swiper/swiper-bundle.min.js';
import 'swiper/swiper.min.css';
import {GETLISTDATA} from "@/apis/api";

export default {
  name: 'home',
  components: {
    top_menu,fo_oter,
    Swiper
  },
  data() {
    return {
      testdata: [],
      testdata1: [],
      testdata2: [],
      testdata3: [],
      testdata4: [],
      testdata5: [],
    };
  },
  mounted() {
    this.jqload();
    this.onloading();
    let metaInfo = {
      title: "动态页面名称",
      keywords: "动态页面关键词",
      description: "动态页面描述"
    }
    this.$store.state.metaInfo = metaInfo;
    console.log(this.$store.state.metaInfo)
  },
  methods: {
    async onloading() {
      this.testdata = await GETHOMEDATA1();
      this.testdata1 = await GETHOMEDATA2();
      this.testdata2 = await GETHOMEDATA3();
      this.testdata5 = await GETHOMEDATA4();
      this.testdata5 = this.testdata5.data.content;
      this.testdata = this.testdata.rows;
      this.testdata1 = this.testdata1.rows;
      //产业
      GETLISTDATA({catid:3,pageNum:1,pageSize:10}).then((res) => {
        if(res.code == 200){
          this.testdata3 = res.rows;
        }
      });

      //新闻中心
      GETLISTDATA({catid:4,pageNum:1,pageSize:5}).then((res) => {
        if(res.code == 200){
          this.testdata4 = res.rows;
          var swiper = new Swiper(".hsSwiper4", {
            autoplay : true,
            slidesPerView: 1,
            pagination: {
              el: ".swiper-pagination",
              type: "fraction",
            },
            navigation: {
              nextEl: ".hsSwiper4 .swiper-button-next",
              prevEl: ".hsSwiper4 .swiper-button-prev",
            },
          });
        }
      });


      GETHOMEDATA3(1).then((res) => {
        this.testdata2 = this.testdata2.data.content;
        // $('#carousel').carousel({
        //   curDisplay: 0, //默认索引
        //   autoPlay: true, //是否自动播放
        //   interval: 30000 //间隔时间
        // });
        var swiper = new Swiper(".hsSwiper1", {
          autoplay:true,
          pagination: {
            el: ".swiper-pagination",
            type: "fraction",
          },
          navigation: {
            nextEl: ".hsSwiper1 .swiper-button-next",
            prevEl: ".hsSwiper1 .swiper-button-prev",
          },
        });

      });

      var swiper1 = new Swiper(".hsSwiper2", {
        autoplay:false,
        pagination: {
          el: ".swiper-pagination",
          type: "fraction",
        },
        navigation: {
          nextEl: ".hsSwiper2 .swiper-button-next",
          prevEl: ".hsSwiper2 .swiper-button-prev",
        },
      });
    },
    jqload() {
      $(function() {
        // new fullpage('.fullpage', {
        //   anchors: ['page1', 'page2', 'page3', 'page4', 'page5'],
        //   licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
        //   menu: '#menu',
        //   afterRender: function () {
        //       $('.ho_pag1').find('.banner_font').delay(50).animate({
        //         left: '50%'
        //       }, 1500);
        //     },

          //   afterLoad: function (anchorLink, index) {
          //     if (index == 1) {
          //       $('.ho_pag1').find('.banner_font').delay(50).animate({
          //         left: '50%'
          //       }, 1500);
          //     }
          //     if (index == 2) {
          //       $('.ho_pag2').find('.font').delay(50).animate({
          //         left: '50%'
          //       }, 1500);
          //     }
          //     if (index == 3) {
          //       $('.ho_pag3').find('.list').delay(50).animate({
          //         left: '50%'
          //       }, 1500);
          //     }
          //     if (index == 4) {
          //       $('.ho_pag4').find('.font_lay').delay(50).animate({
          //         left: '50%'
          //       }, 1500);
          //     }
          //     if (index == 5) {
          //       $('.ho_pag5').find('.list').delay(50).animate({
          //         left: '50%'
          //       }, 1500);
          //     }
          //   },
          // onLeave: function(index, destination, direction){
          //   //onLeave: function (index, direction) {
          //     if (index == '1') {
          //       $('.ho_pag1').find('.banner_font').delay(50).animate({
          //         left: '-150%'
          //       }, 1500);
          //     }
          //     if (index == '2') {
          //       $('.ho_pag2').find('.font').delay(50).animate({
          //         left: '-150%'
          //       }, 1500);
          //     }
          //     if (index == '3') {
          //       $('.ho_pag3').find('.list').delay(50).animate({
          //         left: '-150%'
          //       }, 1500);
          //     }
          //     if (index == '4') {
          //       $('.ho_pag4').find('.font_lay').delay(50).animate({
          //         left: '-150%'
          //       }, 1500);
          //     }
          //     if (index == '5') {
          //       $('.ho_pag5').find('.list').delay(50).animate({
          //         left: '-150%'
          //       }, 1500);
          //     }
          //   }
        // });
      });
    }
    //
  }
}
</script>

<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: 'app',
  data() {
    return {
    };
  }
}
</script>

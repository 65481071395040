import Vuex from "vuex"

export default new Vuex.Store({
  state: {
    metaInfo: {
      title: '辽宁越秀辉山控股股份有限公司 Liaoning Huishan Dairy Group Co.,Ltd.',
      keywords: '辉山乳业、辽宁越秀辉山、Huishan、辽宁越秀',
      description: '辉山乳业——辽宁越秀辉山乳业安全新力量,辉山乳业,致力于成为全球化优质高蛋白乳品制造商和综合乳品服务供应商,从源头解决乳制品安全问题,建立全产业链发展模式,是国内率先成为奶源全部来自规模化自营牧场的...'
    }
  },
  mutations: {
    CAHNGE_META_INFO(state, metaInfo) {
      state.metaInfo = metaInfo;
    }
  }
})
<template>
  <div class="footer">
    <div class="footer_all">
   <div class="footer_lay">
   <dl>
    <dt>联系我们</dt>
    <dd>
      <p>
        <strong>全国免费服务热线：</strong><br />
        {{phone}}<br />
        {{phone1}}
      </p>
      <p>
        <strong>公司地址：</strong>
        {{address}}
      </p>
    </dd>
   </dl>
   <!-- footer_lay -->
   <ul class="ewm_lay">
    <li><img src="@/assets/images/icon11.png" class="fpic" />
      <div class="ewm">
        <img src="@/assets/images/dy.jpg" class="ewm"/>
<!--        <img src="@/assets/images/dy1.jpg"/>-->
      </div>
    </li>
    <li><img src="@/assets/images/icon12.png" class="fpic" />
      <div class="ewm">
      <img src="@/assets/images/wb.jpg" class="ewm" />
      </div>
    </li>
    <li><img src="@/assets/images/icon13.png" class="fpic" />
      <div class="ewm">
      <img src="@/assets/images/wx.jpg" class="ewm" />
      </div>
    </li>
   </ul>

     <div class="beianxinxi">
       <a href="/about" style="color:#FFF;text-decoration: none;">关于辉山 &nbsp;|&nbsp;</a>
       <a target="_blank"  style="color:#FFF;" href="http://beian.miit.gov.cn/">{{footer}} &nbsp; | &nbsp;</a>
       <a target="_blank"  style="color:#FFF;" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=21011302000023">辽公网安备 21011302000023号</a>
     </div>

  </div>
  </div>

    <div class="kf">
      <a href="https://callcenter-callc.huishandairy.com/im_client/?web_plugin_id=15" target="_blank"><img src="@/assets/images/kf.png" /></a>
    </div>
  </div>
</template>
<style>
.kf {
  width: 40px;
  display: block;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 999;
}
</style>
<script>
import {GETDATAS} from "@/apis/api";
export default {
  data() {
    return {
      phone: '',
      phone1: '',
      address: '',
      footer: '',
      testdata4: [],
      testdata5: [],
    };
  },
  mounted() {
    this.getData();
    // if (location.href.indexOf("#reloaded") == -1) {
    //    location.href = location.href + "#reloaded";
    //    location.reload();
    // };
  },
  methods: {
    openkf() {
       window.open("https://callcenter-callc.huishandairy.com/im_client/?web_plugin_id=15");
    },
    getData() {
      GETDATAS({id: "1"}).then((res) => {
        if (res.code == 200) {
          this.phone = res.data.phone;
          this.phone1 = res.data.phone1;
          this.address = res.data.address;
          this.footer = res.data.footer;
        }
      });
    }
  }
}

</script>
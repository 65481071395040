// import Vue from 'vue'
// import VueRouter from 'vue-router'



import {createRouter, createWebHashHistory, createWebHistory} from "vue-router";
import Home from '../views/home.vue'



//Vue.createApp(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/about.vue')
  },
  {
    path: '/chanye',
    name: 'chanye',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "chanye" */ '../views/chanye.vue')
  },
  {
    path: '/dj',
    name: 'dj',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dj" */ '../views/dj.vue')
  },
  {
    path: '/gonggao',
    name: 'gonggao',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "gonggao" */ '../views/gonggao.vue')
  },
  {
    path: '/news',
    name: 'news',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "news" */ '../views/news.vue')
  },
  {
    path: '/zhaopin',
    name: 'zhaopin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "zhaopin" */ '../views/zhaopin.vue')
  },
  {
    path: '/ppcp',
    name: 'ppcp',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ppcp" */ '../views/ppcp.vue')
  },
  {
    path: '/cpsy',
    name: 'cpsy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cpsy" */ '../views/cpsy.vue')
  },
  {
    path: '/gxbsy',
    name: 'gxbsy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cpsy" */ '../views/gxbsy.vue')
  },
  {
    path: '/qywh',
    name: 'qywh',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "qywh" */ '../views/qywh.vue')
  },
  {
    path: '/content',
    name: 'content',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "content" */ '../views/content.vue')
  },
  {
    path: '/searchlist',
    name: 'searchlist',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "searchlist" */ '../views/searchlist.vue')
  },
  {
    path: '/list',
    name: 'list',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "searchlist1" */ '../views/list.vue')
  },
  {
    path: '/CN/Web/QC/ZS',
    name: 'sy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "searchlist1" */ '../views/sy.vue')
  }

]

// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })

const router = createRouter({
  // 4. 通过 createWebHashHistory() 创建 hash 模式。
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes, // (缩写) 相当于 routes: routes
})
// 5. 创建和挂载根实例。
//const app = Vue.createApp({})
// 记得要通过 use 配置参数注入路由，
// 从而让整个应用都有路由功能
// app.use(router)

//app.mount('#app')



export default router

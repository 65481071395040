import Vue, {createApp} from 'vue'
import App from './App.vue'
import router from './router'

import store from "@/apis/store";
import Vuex from "vuex";

const app = createApp(App);

router.beforeEach((to,from,next)=>{
    /*********动态修改keywords和description*************/
    //if(Object.keys(to.meta).length>0 && to.matched.length>0){
        let this_meta=store.state.metaInfo;//to.matched[to.matched.length-1].meta
        //console.log(this_meta,'---this_meta---')
        if(this_meta.title)document.title=this_meta.title
        let head = document.getElementsByTagName('head');
        let meta_keyword=document.createElement('meta');
        if(document.querySelector('meta[name="keywords"]')){
            document.querySelector('meta[name="keywords"]').setAttribute('content',this_meta.keywords)
        }else{
            meta_keyword.setAttribute('name','keywords')
            meta_keyword.setAttribute('content',this_meta.keywords)
            head[0].appendChild(meta_keyword)
        }
        let meta_description=document.createElement('meta');
        if(document.querySelector('meta[name="description"]')){
            document.querySelector('meta[name="description"]').setAttribute('content',this_meta.description)
        }else{
            meta_description.setAttribute('name','description')
            meta_description.setAttribute('content',this_meta.description)
            head[0].appendChild(meta_description)
        }
    //}
    next()
})


// router.beforeEach((to, from, next) => {
//     if (to.meta.metaInfo){
//         store.commit("CAHNGE_META_INFO", to.meta.metaInfo)
//     }
//     next()
// });

app.use(router);
app.use(store);

app.mount('#app');
// Vue.config.productionTip = false

// new Vue({
//   router,
//   render: h => h(App),
// }).$mount('#app')

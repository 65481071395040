//home.js 用于获取首页json数据

import req from './http.js'  //引入封装好的axios

//在这里定义了一个登陆的接口，把登陆的接口暴露出去给组件使用
export const GETHOMEDATA1 =params=>req('get','cms/article/list/?catid=41&pageNum=1&pageSize=5'+'&orderColumn=sort desc,publish_time desc',params)
export const GETHOMEDATA2 =params=>req('get','cms/article/list/?catid=42&pageNum=1&pageSize=5'+'&orderColumn=sort desc,publish_time desc',params)
export const GETHOMEDATA3 =params=>req('get','/system/advert/1',params)
export const GETHOMEDATA4 =params=>req('get','/system/advert/3',params)
//这里使用了箭头函数，转换一下写法：
//export const GETTREEDATA=function(req){
//  return req('post','/GetTreeListForSoilByRegion',params)
//}
